import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    currentRecord: null,
    uuid: null,
    records : [],
    config: {
        nt_uuid: null,
        data: {
            periodo: null
        }
    },
    tipoNotificaciones : []
}

export const personaSlice = createSlice({
    name: 'persona',
    initialState,
    reducers: {
        personaEditing: (state, action) => {
            state.currentRecord = action.payload;
            state.uuid          = action.payload?.uuid;
            state.loading       = false;
        },
        personaCancelEdit: (state) =>{
            state.currentRecord = null;
            state.uuid          = null;
        },
        personaSetConfig : (state,action) =>{
            state.config        = action.payload
        },
        personaSetTiposNotificaciones : (state,action) =>{
            state.tipoNotificaciones        = action.payload
        },
        setPersonaLoading : (state)=>{
            state.loading = true;
        }
    }
})

// Action creators are generated for each case reducer function
export const { personaEditing, personaCancelEdit, personaSetConfig, personaSetTiposNotificaciones, setPersonaLoading } = personaSlice.actions

export default personaSlice.reducer