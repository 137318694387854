
import  {  useEffect } from 'react';
import PropTypes from "prop-types";

import Swal from 'sweetalert2';

export const Loading = ({loading}) => {

    useEffect(() => {
        if(loading == true){
            Swal.showLoading();
        }else{
            Swal.close();
        }
    }, [loading])
    

    return (
        <>
            
        </>   
    )
}

Loading.propTypes = {
    loading: PropTypes.any
};

export default Loading;