import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    cdc_uuid: null,
    periodo:  null,
    transacciones : [],
    loading : false,
    filtro: {
        fecha_inicial: "",
        fecha_final: ""
    },
    downloading: false,
    refreshing : false
}

export const estadoCuentaSlice = createSlice({
    name: 'estadocuenta',
    initialState,
    reducers: {
        estadoCuentaViewing: (state, action) => {
            state.cdc_uuid      = action.payload.cdc_uuid;
            state.transacciones = action.payload;
            state.loading = false;
        },
        estadoCuentaCancelWiewing: (state) =>{
            state.cdc_uuid = null;
        },
        saveLoading: (state) =>{
            state.loading = true;
        },
        saveFiltro: (state, action) =>{
            state.filtro = action.payload;
        },
        savePeriodo: (state, action) =>{
            state.periodo = action.payload;
        },
        isDownloading:(state, action)=>{
            state.downloading = action.payload;
        },
        isRefreshing: (state, action) =>{
            state.refreshing = action.payload;
        }
    }
})

// Action creators are generated for each case reducer function
export const { estadoCuentaViewing, estadoCuentaCancelWiewing, saveLoading, saveFiltro, savePeriodo, isDownloading, isRefreshing } = estadoCuentaSlice.actions

export default estadoCuentaSlice.reducer