import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {windowResize} from "../store/slice/ventanaSlice";


export const useWindowHeight = (initialHeight) => {

    const dispatch = useDispatch();
    const { windowHeight } = useSelector(state=> state.ventana);
    //const [windowHeigth, setWindowHeight ] = useState(initialHeight);

    const handleWindow = ()=>{
        dispatch( windowResize(window.innerHeight - 130) )
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindow);
        return () => window.removeEventListener("resize", handleWindow);
    }, []);

    useEffect(() => {
        dispatch( windowResize(window.innerHeight - 130) )
    }, []);

    return {
        windowHeight
        //setWindowHeight
    }

}