
import PropTypes from "prop-types";

import SoftTypography from "components/SoftTypography";

function LabelCellAny( { label } ) {

    return (
        <SoftTypography
            variant="button"
            fontWeight="regular"
            sx={{ display: "inline-block", width: "max-content" }}
        >
            { label }
        </SoftTypography>
    );
}

// Typechecking props for the ListItem
LabelCellAny.propTypes = {
    label: PropTypes.string.isRequired,
    align: PropTypes.string
};

export default LabelCellAny;





























