import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    windowHeight: 800,
}

export const ventanaSlice = createSlice({
    name: 'ventana',
    initialState,
    reducers: {
        windowResize: (state, action) => {
            state.windowHeight = action.payload;
        }
    }
})

// Action creators are generated for each case reducer function
export const { windowResize } = ventanaSlice.actions

export default ventanaSlice.reducer