import useMenuHook from "../hooks/MenuHook";
import MenuItemCard from "./MenuItemCard";
import Grid from "@mui/material/Grid";

export const MenuItem = () => {

    const menuData = useMenuHook;

    return (
        <>
            <Grid container spacing={3}>
                {
                    menuData.map((element)=>{
                        return ( 
                            <Grid  key={  "G"+element.key } item xs={12} sm={6} xl={3}>
                                <MenuItemCard
                                    key={  element.key }
                                    title={{ text: "Transacciones" }}
                                    count=""
                                    percentage={{ color: "success", text: "" }}
                                    icon={{ color: "info", component: "paid" }}
                                    componentUrl = { element.componentUrl }
                                    bgColor = "color6V"
                                    direction = "right"
                                />
                        </Grid>
                        )
                    })
                }
            </Grid>

           

            
        </>
    )
}

export default MenuItem;
