/**
 =========================================================
 * Soft UI Dashboard React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
// @mui material components
import {useDispatch, useSelector} from "react-redux";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
// Soft UI Dashboard React base styles

import {Grid, useMediaQuery} from "@mui/material";
import PaymentLayout from "../authentication/components/PaymentLayout";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import {consultarTransaccion} from "../../store/thunk/cobranza";
import {useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import * as React from "react";
import CardMessages from "./components/CardMessages";
import CashPayment from "./components/CashPayment";
import {v4} from "uuid";
import Card from "@mui/material/Card";

import visaCard from 'assets/alpha-theme/images/cards/icn_visa.svg';
import masterCard from 'assets/alpha-theme/images/cards/icn_mc.svg';
import amexCard from 'assets/alpha-theme/images/cards/ic_amex.svg';
import banorteLogo from 'assets/alpha-theme/images/cards/option/Banorte.png';
import sevenLogo from 'assets/alpha-theme/images/cards/option/7-Eleven.png';
import woolwortLogo from 'assets/alpha-theme/images/cards/option/Woolworth.png';
import telecommmLogo from 'assets/alpha-theme/images/cards/option/Telecomm.png';
import yastasLogo from 'assets/alpha-theme/images/cards/option/Yastás.png';
import farmaciasLogo from 'assets/alpha-theme/images/cards/option/Farmacias del Ahorro.png';
import chedrauiLogo from 'assets/alpha-theme/images/cards/option/Chedraui.png';
import delSolLogo from 'assets/alpha-theme/images/cards/option/Del Sol.png';
import oxxo_pay from 'assets/alpha-theme/images/cards/option/oxxo-pay-03.svg';
import {setMetodoPago} from "../../store/slice/cobranzaSlice";
import CardPayment from "./components/CardPayment";
import CardPrintInvoice from "./components/CardPrintInvoice";
import CardPrintReference2 from "./components/CardPrintReference2";
import PaypalPayment from "./components/PaypalPayment";
import logox from "assets/alpha-theme/images/cards/cobranza3v2.png";
import DocumentsToPay from "./components/DocumentsToPay";
import BtnVolverAListado from "./components/BtnVolverAListado";
import SectionHeader from "./components/SectionHeader";
import Resumen from "./components/Resumen";
import { useNumberFormat } from "../../customHooks/useNumberFormat";
import Ambiente from "../../appComponents/Ambiente";
function Netpay() {
    const isMobile = useMediaQuery('(max-width:600px)');
    const { uuid } = useParams();
    const dispatch = useDispatch();
    const { numberFormat } = useNumberFormat();
    const { status, statusMessage,cuenta,referencia,generarReferenciaNetPay, metodoPago, transaccion, liquidado,partidasAPagar } = useSelector(state => state.cobranza);
    const { netpay: deNeptpay, documentosPorPagar } = transaccion;
    const [optionWidth, setOptionWidth]  = useState();

    useEffect(()=>{
        dispatch( consultarTransaccion(uuid) );
    },[])

     const optionClicked = (event, option) =>{
        event.preventDefault();
        dispatch( setMetodoPago(option) );
     }

    useEffect(()=>{

        let columns = transaccion.netpay.metodos.length;
        columns+= transaccion.device?.paypal !== null ? 1 : 0;
        setOptionWidth( 12/ columns );
    },[transaccion.netpay])

    useEffect(()=>{
        let columns = transaccion.netpay.metodos.length;
        if(columns === 1 && transaccion.device?.paypal == null && documentosPorPagar == null){
            dispatch( setMetodoPago( transaccion.netpay.metodos[0] ) )
        }
    },[transaccion.netpay])

    const paymentCardComponent = <SoftBox bgColor={"color18"} borderRadius={"12px"} >
        <SectionHeader cuenta={ cuenta } />
        <Grid container >
            <CardPayment />
        </Grid>
    </SoftBox>

    const PaymentDefault =<CardMessages message={ statusMessage } iconx={"fa-solid fa-link"}  />

    const netpayOptionPayment =[
        {
            'type':"TARJETA",
            'componente' :<Grid item xs={12} md={ optionWidth } xl={ optionWidth } marginBottom={1} pl={1} textAlign={"center"} key={ v4() } >

                <Card sx={{ height:"99%",backgroundColor:"#E4F2F9",marginRight:1, cursor:"pointer" }} onClick={ event => optionClicked(event,'TARJETA') } >
                    <SoftBox  justifyContent="space-between" alignItems="center" pt={1} px={2}>
                        <SoftBox key={v4()}  sx={{ textAlign:"center" }} >
                            <label className={"IGenericLabel-2f"}>Tarjeta</label>
                        </SoftBox>
                        <SoftBox>
                            {
                                transaccion.monto !== transaccion.montoReferencia &&
                                <SoftBox className={ "IGenericLabel-1-5f" }>
                                    $ {  numberFormat(transaccion.monto, 2) } MXN
                                </SoftBox>
                            }
                        </SoftBox>
                    </SoftBox>
                    {
                        !isMobile && <SoftBox pt={2} >
                            <SoftBox>
                                <img className={"ICardSvG"} src={ visaCard } height={100} width={100} title={"Visa"} alt={"Icono Visa" } />
                                <img className={"ICardSvG"} src={ amexCard } height={94} width={100} title={"American Express"} alt={"Icono American Express"} />
                                <img className={"ICardSvG"} src={ masterCard } height={100} width={100} title={"MasterCard"} alt={ "Icono Master Card" } />
                            </SoftBox>
                        </SoftBox>
                    }
                </Card>

            </Grid>
        },{
            'type':"REFERENCIA",
            componente: <Grid item xs={12} md={ optionWidth } xl={ optionWidth } marginBottom={1} pl={1} textAlign={"center"} key={ v4() }>

                <Card sx={{ height:"99%",backgroundColor:"#E4F2F9",marginRight:1, cursor:"pointer" }} onClick={ event => optionClicked(event,'REFERENCIA') } >
                    <SoftBox  justifyContent="space-between" alignItems="center" pt={1} px={2}>
                        <SoftBox key={v4()}  sx={{ textAlign:"center" }} >
                            <label className={"IGenericLabel-2f"}>Efectivo</label>
                        </SoftBox>
                        <SoftBox>
                            {
                                transaccion.monto !== transaccion.montoReferencia &&
                                <SoftBox className={ "IGenericLabel-1-5f" }>
                                    $ {  numberFormat(transaccion.montoReferencia, 2) } MXN
                                </SoftBox>
                            }
                        </SoftBox>
                    </SoftBox>
                    {
                        !isMobile && <SoftBox p={4} >
                            <SoftBox>
                                <img className={"ICardSvG2 oxxopay-img"} src={oxxo_pay} alt={"OXXO PAY"} width={"27vh"} title={"OxxoPay"} />
                            </SoftBox>
                        </SoftBox>
                    }

                </Card>

            </Grid>
        }
    ]

    const PaymentOptions = <>
        <SectionHeader cuenta={ cuenta} />
        <Grid container>

            <Resumen monto={ transaccion.monto } />
            <Grid item xs={12} md={12} xl={12}  textAlign={"center"} className={"sec-indicaciones"}  >
                {
                    transaccion.netpay?.metodos?.length + (transaccion.device?.paypal !== null ?1:0 ) > 1 ?
                        "Usted cuenta con las siguientes opciones de pago, elija el que más se ajuste a sus necesidades":
                        "Por favor utilice la siguiente forma de pago:"
                }
            </Grid>
            {
                transaccion.netpay?.metodos?.map(element => {
                    const compt = netpayOptionPayment.find( item => element === item.type  );
                    return compt.componente;
                })
            }
            {
                transaccion.device?.paypal !== null ?
                    <Grid item xs={12} md={ optionWidth } xl={ optionWidth } pl={1} textAlign={"center"}>
                        <PaypalPayment columns={ optionWidth } />
                    </Grid>
                    :""
            }
            {
                partidasAPagar.length > 0 && <BtnVolverAListado />
            }
        </Grid>
    </>

    return (
        <PaymentLayout
            title=""
            description=""
        >
            {
                process.env.REACT_APP_ENVIRONMENT === "demo" && <Ambiente />
            }
            { status === "EN PROCESO" && metodoPago === 'TARJETA' ? paymentCardComponent :"" }
            { status === "EN PROCESO" && metodoPago  === 'REFERENCIA' && referencia === "" ? <CashPayment /> :"" }
            { status === "EN PROCESO" && liquidado === null && metodoPago === "" && documentosPorPagar.cantidadDocumentos === 0 ?  PaymentOptions :"" }
            { status === "EN PROCESO" && liquidado === null && metodoPago === "" && (documentosPorPagar.cantidadDocumentos > 0 ) ?  <DocumentsToPay /> :"" }
            { status === "EN PROCESO" && metodoPago === "PAYPAL" ?  <PaypalPayment /> :"" }

            { liquidado !== null ? <CardPrintInvoice />:"" }
            { referencia !== "" ?  <CardPrintReference2 /> :"" }
            { status === "error" ? PaymentDefault :"" }
        </PaymentLayout>
    );

}

export default Netpay;