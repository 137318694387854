/**
 =========================================================
 * Soft UI Dashboard React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SignIn from "layouts/authentication/sign-in";

// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// @mui material components
import Grid from "@mui/material/Grid";

import Swal from 'sweetalert2';

import api_core from '../../../services/config';

import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import IconSave from '@mui/icons-material/Save';
import IconBack from '@mui/icons-material/ArrowBack';
import IconSearch from '@mui/icons-material/Search';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/* componenets */
import ModalBusqueda from "./ModalBusqueda";
import ListadoCuentas from "./components/ListadoCuentas";
import CuentaDetalle from "./components/CuentaDetalle";
import Autorizadores from "./components/Autorizadores.jsx";
import ItemCuenta from "./components/ItemCuenta.jsx";
import ListadoEmpresas from "./components/ListadoEmpresas.jsx";
import ListadoLicencias from "./components/ListadoLicencias.jsx";

import ValidatorRFC from "Repository/ValidatorRFC.js";
import CentroDeCosto from "Repository/Banca/CentroDeCosto.js";
import Filtro from "../../cuentas/components/Filtro";
import * as React from "react";
import ActionMenu from "./components/ActionMenu";
import {v4} from "uuid";
import ServicioNetpay from "../../registro/components/ServicioNetpay";
import Registro from "../../registro";
import ServicioPaypal from "../../registro/components/ServicioPaypal";
import CuentaMaster from  "./../../../Repository/Banca/Cuentas";

function Cuentas() {
    const [cuenta_uuid, setCuenta] = useState('');
    const [alias, setAlias] = useState('');
    const [razon_social, setRazonSocial] = useState('');
    const [rfc, setRfc] = useState('');
    const [serie, setSerie] = useState('');
    const [nombreComercial, setNombreComercial] = useState('');
    const [idCliente, setIdCliente] = useState('');
    const [stpNombre, setStpNombre] = useState('');
    const [direccion, setDireccion] = useState('');

    const [errores, setErrores] = useState('');
    const [isreaonly, setIsReadOnly] = useState(false);

    /* Estado de autorizador */
    /* Estado de autorizador */
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [uuidSelected, setUuidSelected] = useState('');
    const [customerSelected, setCustomerSelected] = useState('');

    const [uuidCuentaSelected, setUuidCuentaSelected] = useState('');
    const [menuSelectedItem, setMenuSelectedItem] = useState(3);

    const [menu, setMenu] = useState(null);

    const openMenu = ({ currentTarget }) => setMenu(currentTarget);
    const closeMenu = () => setMenu(null);

    const [showMainForm, setShowMainForm] = useState(false);
    const [expandedMainForm, setExpandedMainForm ] = useState(false);
    const [refreshItemCuenta, setRefreshItemCuenta] = useState(0);

    const validadorRfc = new ValidatorRFC();
    const cdc = new CentroDeCosto();
    const cuentax = new CuentaMaster();

    useEffect(() => {
        const cuenta_uuid_stored = localStorage.getItem(`cCuentaUuid`);
        if(cuenta_uuid_stored  != undefined &&  cuenta_uuid_stored != '' ){
            setUuidCuentaSelected(cuenta_uuid_stored);
        }
        const optionDefautlt = localStorage.getItem("defaultOptionMenu");//5
        const optionMenu = parseInt(optionDefautlt);
        if(optionMenu > 0 ) setMenuSelectedItem(optionMenu);

    }, [])

    useEffect(()=>{
        if(expandedMainForm){
            getConsecutivo();
        }
    },[expandedMainForm])


    /* validacion en tiempo real de lo que se captura en los campos, marcar en rojo o verde segun lo que se esta ingresando */
    function getErrAlias(negative){
        if(negative){
            if(alias.length <= 3) return true;
            return false;
        }else{
            if(alias.length > 3) return true;
            return false;
        }
    }

    function getErrNombreComercial(negative){
        if(negative){
            if(nombreComercial.length <= 3) return true;
            return false;
        }else{
            if(nombreComercial.length > 3) return true;
            return false;
        }
    }

    function getErrRazonSocial(negative){
        if(negative){
            if(razon_social.length <= 5) return true;
            return false;
        }else{
            if(razon_social.length > 6) return true;
            return false;
        }
    }

    function getErrRFC(negative){

        if(negative){
            if(rfc.length < 12 || rfc.length > 13) return true;
            return false;
        }else{
            if(rfc.length == 12 || rfc.length == 13) return true;
            return false;
        }
    }
    function getErrSerie(negative){
        if(negative){
            if(serie.length < 1) return true;
            return false;
        }else{
            if(serie >= 1 ) return true;
            return false;
        }
    }

    /* Nombres de los autorizadorees start   */
    function getErrCustomerSelected(negative){
        if(negative){
            if(customerSelected.length < 3) return true;
            return false;
        }else{
            if(customerSelected.length > 3 ) return true;
            return false;
        }
    }

    function getErrStpNombre(){
        var regex = new RegExp("^[a-zA-Z0-9]+$");
        if (!regex.test(stpNombre)) {
            return true;
        }
        if(stpNombre.length > 15) return true;
        return false;
    }

    /* Nombres de los autorizadorees end   */

    //validacion otra vez todo de corrido
    function validate (){
        let hasError = false;
        let counter = 0;
        if(alias.length <= 3) { counter+=1; }
        if(razon_social.length <= 5) { counter+=1 }
        if(rfc.length < 12 || rfc.length > 13) { counter+=1 }
        if(serie.length < 1) {counter+=1; }
        if(nombreComercial.length < 3) {counter+=1; }
        if(uuidSelected.length === '') {counter+=1; }

        const validesRFC = validadorRfc.rfcValido(rfc);
        if(!validesRFC){
            Swal.fire({
                icon: 'error',
                title: 'Notificaciones',
                text: 'RFC inválido.',
                showConfirmButton: true,
                confirmButtonText: 'Aceptar'
            })
            return true;
        }

        if(counter > 0){//XD no has capturado todo
            return (hasError = true);
        }

        return hasError;

    }

    //envia datos al servidor
    const sendData = async e=>{
        e.preventDefault();
        setErrores('');

        if(!validate()){
            const data = {
                rfc:rfc.trim(),
                alias:alias.trim(),
                razon_social:razon_social.trim(),
                serie:serie.trim(),
                persona_uuid: uuidSelected,
                nombreComercial:nombreComercial.trim(),
                direccion:direccion.trim()
            }

            await  cuentax.store(data)
                .then(response => {
                    let data = response;
                    if(data.status === 'ok'){

                        Swal.fire({
                            icon: 'success',
                            title: 'Excelente',
                            text: 'Registro almacenado con éxito.',
                            showConfirmButton: false,
                            confirmButtonText: 'Aceptar'
                        })
                        setIsReadOnly(true);
                        setCuenta(data.response.cuenta);
                        setUuidCuentaSelected(data.response.cuenta);
                        setMenuSelectedItem(3);
                        setExpandedMainForm(false);

                        setNombreComercial('');
                        setAlias('');
                        setRfc('');
                        setSerie('');
                        setUuidSelected('');
                        setCustomerSelected('');
                        setRazonSocial('');
                        setIdCliente('');
                        setStpNombre('');
                        setDireccion('');
                    }else{
                        setErrores(data.response.text);
                    }
                });

        }else{
            //alert(`Parece que tienes errores`);
            setErrores('Por favor rellene los campos necesarios para poder continuar');
        }

    }

    const handledCuentaSelected = (uuid)=>{
        setUuidCuentaSelected(uuid);
        setMenuSelectedItem(3);
    }

    const onCloseViewDetalleHandle = ()=>{
        localStorage.removeItem("cCuentaUuid");
        localStorage.removeItem("defaultOptionMenu");
        setUuidCuentaSelected('');
        setMenuSelectedItem(0);
    }

    const onHandleOpenForm = (event)=>{
        setIsOpenModal(false);
    }

    const showSearchPopup = (event)=>{
        event.preventDefault();
        setIsOpenModal(true);
    }

    const handleOnSelectedItem = (uuid, nombre_completo)=>{
        setIsOpenModal(false);
        setUuidSelected(uuid);
        setCustomerSelected(nombre_completo)
    }

    const onMenuSelecteItem = (value)=>{
        setMenuSelectedItem(value);
    }

    const onCloseComponent= ()=>{
        setMenuSelectedItem(0);
    }

    const onAccordionClick = (event)=>{
        setExpandedMainForm(!expandedMainForm);
    }

    const onCancelMainForm = ()=>{
        setExpandedMainForm(false);
        setIsReadOnly(false);
        setCuenta('');
        setAlias('');
        setRazonSocial('');
        setSerie('');
        setNombreComercial('');
        setRfc('');
        setUuidSelected('');
        setCustomerSelected('');
        setIdCliente('');
        setStpNombre('');
        setDireccion('');
    }

    /* refrescar add autorizadores, el combo cuando se agregue un autorizador a la lista  */
    const onAddAutorizador= ()=>{
        setRefreshItemCuenta( refreshItemCuenta + 1 );
    }

    const actualizadComboAutorizadores = ()=>{
        setRefreshItemCuenta( (oldValue) => oldValue + 1 );
    }

    const getConsecutivo = async ()=>{
        const data = await cdc.getCliente();
        setIdCliente(data.response.data);
    }


    const renderMenu = (
        <Menu
            id="simple-menu"
            anchorEl={menu}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={Boolean(menu)}
            onClose={closeMenu}
        >
            <MenuItem onClick={closeMenu}>Contraer</MenuItem>
            <MenuItem onClick={closeMenu}>Expandir</MenuItem>
        </Menu>
    );

    const handleClickMenu = ( value) =>{
        console.log(value);
        setMenuSelectedItem( value );
    }

    const formularioRegistro = <>

                <SoftBox

                >

                    <SoftBox pt={2} pb={1} px={1} sx={{ border:"5px solid #f7f7f7", }}>
                        <SoftBox component="form" role="form" onSubmit={sendData}>
                            <SoftBox display="flex" alignItems="center" lineHeight={0}>
                                <SoftTypography variant="button" fontSize="xxs" color="error"  >
                                    { errores }
                                </SoftTypography>
                            </SoftBox>
                            <Grid container spacing={3} >



                                <Grid item xs={12} md={6} xl={3}>
                                    <SoftBox lineHeight={0}>
                                        <SoftTypography variant="caption" color="text" >
                                            Alias de la Cuenta
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox>
                                        <input type={"text"} className={"ITextbox"} name={"alias"} value={ alias } maxLength={30}  onChange={ e => setAlias(e.target.value) } autoComplete={"on"} />
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12} md={6} xl={3}>
                                    <SoftBox lineHeight={0}>
                                        <SoftTypography variant="caption" color="text">
                                            Nombre Comercial
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox>
                                        <input type={"text"} className={"ITextbox"} name={"nombreComercial"} value={ nombreComercial } maxLength={150}  onChange={ e => setNombreComercial(e.target.value) } autoComplete={"on"} />
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12} md={6} xl={6}>
                                    <SoftBox lineHeight={0}>
                                        <SoftTypography variant="caption" color="text">
                                            Razón Social
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox >
                                        <input type={"text"} className={"ITextbox"} name={"razon_social"} value={ razon_social } maxLength={150}
                                               onChange={ e => setRazonSocial(e.target.value) } autoComplete={"on"} />
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12} md={6} xl={3}>
                                    <SoftBox  lineHeight={0}>
                                        <SoftTypography variant="caption" color="text">
                                            RFC
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox>
                                        <input type={"text"} className={"ITextbox"} name={"rfc"} value={ rfc } maxLength={13}
                                               onChange={ e => setRfc(e.target.value) } autoComplete={"on"} />
                                    </SoftBox>
                                </Grid>

                                {
                                    /**
                                     * <Grid item xs={12} md={6} xl={3}>
                                     *                             <SoftBox mb={1} lineHeight={0}>
                                     *                                 <SoftTypography variant="caption" color="text">
                                     *                                     Centro de Costos STP
                                     *                                 </SoftTypography>
                                     *                             </SoftBox>
                                     *                           <SoftBox>
                                     *                             <SoftInput type="text" placeholder="Cliente" value={idCliente}
                                     *                             maxLength="10" name="idCliente" id="idCliente"
                                     *                             onChange={ e => setIdCliente(e.target.value) } autoComplete="off"
                                     *                             inputProps={{ maxLength: 3 }}
                                     *                             />
                                     *                           </SoftBox>
                                     *                         </Grid>
                                     *
                                     *                         <Grid item xs={12} md={6} xl={3}>
                                     *                             <SoftBox mb={1} lineHeight={0}>
                                     *                                 <SoftTypography variant="caption" color="text">
                                     *                                     Nombre Centro de Costos STP
                                     *                                 </SoftTypography>
                                     *                             </SoftBox>
                                     *                           <SoftBox>
                                     *                             <SoftInput type="text" placeholder="Nombre STP" value={stpNombre}
                                     *                             inputProps={{ maxLength: 15 }} name="stpNombre" id="stpNombre"  error={ getErrStpNombre() }
                                     *                             onChange={ e => setStpNombre(e.target.value) } autoComplete="off"
                                     *                             />
                                     *                           </SoftBox>
                                     *                         </Grid>
                                     */
                                }

                                <Grid item xs={12} md={6} xl={3}>
                                    <SoftBox mb={1} lineHeight={0}>
                                        <SoftTypography variant="caption" color="text">
                                            Licencia
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox>
                                        <input type={"text"} className={"ITextbox"} name={"serie"} value={ serie } maxLength={50}
                                               onChange={ e => setSerie(e.target.value) } autoComplete={"on"} />
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12} md={6} xl={6}>
                                    <SoftBox mb={1} lineHeight={0}>
                                        <SoftTypography variant="caption" color="text">
                                            Dirección de la empresa
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox>
                                        <input type={"text"} className={"ITextbox"} name={"direccion"} value={ direccion } maxLength={50}
                                               onChange={ e => setDireccion(e.target.value) } autoComplete={"on"} />
                                    </SoftBox>
                                </Grid>

                            </Grid>

                            <Grid container spacing={3} pt={1}>

                                <Grid item xs={12} md={8} xl={10}>
                                    <SoftBox lineHeight={0}>
                                        <SoftTypography variant="caption" color="text">
                                            Propietario
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox >
                                        <input type={"text"} className={"ITextbox"} name={"direccion"} value={ customerSelected } maxLength={50}
                                               onChange={ e => setCustomerSelected(e.target.value) } autoComplete={"on"} readOnly={true} />
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} md={4} xl={2}>
                                    <SoftBox pt={2}>
                                        <button className={"btn-alpha-info"} type={"submit"} onClick={ showSearchPopup }>
                                            <i className="fa-solid fa-magnifying-glass"></i> Buscar
                                        </button>
                                    </SoftBox>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} pt={2} >
                                <Grid item xs={12} md={6} xl={8}></Grid>
                                <Grid item xs={12} md={3} xl={2}>

                                </Grid>
                                <Grid item xs={12} md={3} xl={2}>

                                    <button className={"btn-alpha-success-2 pad-element"} type={"submit"}  >
                                        <i className="fa-solid fa-circle-check"></i> Registrar
                                    </button>
                                </Grid>

                            </Grid>

                        </SoftBox>
                    </SoftBox>
                </SoftBox>

    </>

    return (
        <DashboardLayout>
            <DashboardNavbar light={ true} />
            <SoftBox py={3}>



                <ModalBusqueda isOpen={isOpenModal} title="Buscar Cliente" onOpenForm={ (value)=>onHandleOpenForm(value) }
                               onSelectedItem = { (uuid, nombre_completo) => handleOnSelectedItem(uuid, nombre_completo) }
                />
                { !uuidCuentaSelected &&   <Filtro bodyContent={ formularioRegistro }  title={"Crear cuenta"} icon={""} open={ false } /> }

            </SoftBox>


            <SoftBox>
                { !uuidCuentaSelected && <ListadoCuentas subtitle="Cuentas" onSelectedCuenta = { (uuid) => handledCuentaSelected(uuid) } /> }
                { uuidCuentaSelected && <CuentaDetalle cuenta_uuid={ uuidCuentaSelected } onCloseViewDetalle = { () => onCloseViewDetalleHandle() } onMenuSelecteItem = { (value) => onMenuSelecteItem(value)   } /> }
                { uuidCuentaSelected &&  <ActionMenu onSelectedMenu = { (value) => handleClickMenu(value) } defaultOption={ menuSelectedItem } /> }
                { menuSelectedItem === 1 ? <ListadoEmpresas cuenta_uuid={ uuidCuentaSelected }  onCloseComponent= {onCloseComponent} /> :'' }
                { menuSelectedItem === 2 ? <ListadoLicencias cuenta_uuid={ uuidCuentaSelected }  onCloseComponent= {onCloseComponent} /> :'' }
                { menuSelectedItem === 3 && uuidCuentaSelected !== '' ? <Autorizadores cuenta_uuid={ uuidCuentaSelected } onCloseViewDetalle = { () => onCloseViewDetalleHandle() }
                                                         onAddAutorizador ={ ()=>onAddAutorizador() } refreshDropDown = { ()=>actualizadComboAutorizadores() } onCloseComponent= {onCloseComponent}  /> : '' }
                { menuSelectedItem === 4  ? <ItemCuenta key={ v4() } uuid={ uuidCuentaSelected } onCloseViewDetalle = { () => onCloseViewDetalleHandle() } refresh= { refreshItemCuenta } /> :'' }
                { menuSelectedItem === 5 ? <ServicioNetpay cuentaUuidSelected={ uuidCuentaSelected } /> :""  }
                { menuSelectedItem === 6 ? <ServicioPaypal cuentaUuidSelected={ uuidCuentaSelected } /> :""  }
            </SoftBox>




        </DashboardLayout>
    );

}

export default Cuentas;