import { Routes, Navigate, Route } from "react-router-dom"
import SignIn from "../layouts/authentication/sign-in";

export const AuthRoutes = () => {
    return (
        <Routes>
            <Route path="/authentication/sign-in" element={ <SignIn /> } />
            <Route path="/*" element={ <Navigate to="authentication/sign-in" /> } />
        </Routes>
    )
}