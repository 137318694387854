/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { useNavigate } from "react-router-dom";

function MenuItemCard({ bgColor, title, count, percentage, icon, direction, componentUrl }) {

    const navigate = useNavigate();

    const onItemClicked = ()=>{
        navigate(componentUrl);
    } 

  return (
        <Card>
            <SoftBox bgColor={bgColor} >
                <SoftBox p={2}>
                <Grid container alignItems="center">
                    {direction === "left" ? (
                    <Grid item>
                        <SoftBox
                        variant="gradient"
                        bgColor={bgColor === "white" ? icon.color : "white"}
                        color={bgColor === "white" ? "white" : "dark"}
                        width="3rem"
                        height="3rem"
                        borderRadius="md"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        shadow="md"
                        >
                        <Icon fontSize="small" color="inherit">
                            {icon.component}
                        </Icon>
                        </SoftBox>
                    </Grid>
                    ) : null}
                    <Grid item xs={8}>
                    <SoftBox ml={direction === "left" ? 2 : 0} lineHeight={1}>
                        <SoftTypography
                        variant="button"
                        color={bgColor === "white" ? "text" : "white"}
                        opacity={bgColor === "white" ? 1 : 0.7}
                        textTransform="capitalize"
                        fontWeight={title.fontWeight}
                        >
                        {title.text}
                        </SoftTypography>
                        <SoftTypography
                        variant="h5"
                        fontWeight="bold"
                        color={bgColor === "white" ? "dark" : "white"}
                        >
                        {count}{" "}
                        <SoftTypography variant="button" color={percentage.color} fontWeight="bold">
                            {percentage.text}
                        </SoftTypography>
                        </SoftTypography>
                    </SoftBox>
                    </Grid>
                    {direction === "right" ? (
                    <Grid item xs={4}>
                        <SoftBox
                        bgColor={bgColor === "white" ? icon.color : "white"}
                        color={bgColor === "white" ? "white" : "dark"}
                        width="3rem"
                        height="3rem"
                        marginLeft="auto"
                        borderRadius="md"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        shadow="md"
                        >
                            <SoftTypography  component="a"
                                href="#" color={ bgColor } fontWeight="bold" onClick={ onItemClicked } >
                                <Icon fontSize="large" color="inherit">
                                    {icon.component}
                                </Icon>
                            </SoftTypography>
                        
                        </SoftBox>
                    </Grid>
                    ) : null}
                </Grid>
                </SoftBox>
            </SoftBox>
        </Card>
    );
}

// Setting default values for the props of MiniStatisticsCard
MenuItemCard.defaultProps = {
  bgColor: "white",
  title: {
    fontWeight: "medium",
    text: "",
  },
  percentage: {
    color: "success",
    text: "",
  },
  direction: "right",
};

// Typechecking props for the MiniStatisticsCard
MenuItemCard.propTypes = {
  bgColor: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "color1V","color2V","color3V","color4V","color5V","color6V","color7V",
    "color1A","color2A","color3A","color4A","color5A","color6A","color7A",
  ]),
  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
    text: PropTypes.string,
  }),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
      "color6V",
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  icon: PropTypes.shape({
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark", "color11",]),
    component: PropTypes.node.isRequired,
  }).isRequired,
  direction: PropTypes.oneOf(["right", "left"]),
  componentUrl: PropTypes.string
};

export default MenuItemCard;
