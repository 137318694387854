import React, {  useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Switch from "@mui/material/Switch";

import Swal from 'sweetalert2';


function EstatusV2({ item ,currentObject , onStateUpdateItem}) {

    const [statex, setStatex ] = useState( item.disabled == null? true: false );

    const  operaciones  = async (value)=>{
        const passtext = await Swal.fire({
            title: 'Confirmaciones',
            text: "¿Para confirmar el cambio de estatus de la empresa coloque su código de confirmación?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            input: 'password',
            allowOutsideClick: false
            });
        if(passtext.isConfirmed){
            cambiarEstado(item.uuid, value, passtext.value, currentObject.cambiarEstado);
        }else{
            setStatex(!value);
        }

    }

    const cambiarEstado = (uuid, value,password,/* callback */ callback) => {
        
        callback(item,value,password).then((response) =>{
            if(response.status == 'ok'){
                Swal.fire({
                    title: 'Notificaciones',
                    text: response.response.text,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar',
                    allowOutsideClick: false
                    });
                    onStateUpdateItem(uuid);
            }else{
                Swal.fire({
                    title: 'Notificaciones',
                    text: response.response.text,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Aceptar',
                    allowOutsideClick: false
                    });
                    setStatex(!value);
            }
           
        }); 
        
    }
    
    
    const handleCheckChange = (event)=>{
        setStatex( event.target.checked ) ;
        operaciones(event.target.checked);
    }

    return(
        <SoftBox mt={0.25}>
            <Switch  checked={ (statex) } onChange={ (event) => handleCheckChange(event) } />
        </SoftBox>
    )
    
}

EstatusV2.propTypes = {
    item: PropTypes.object,
    currentObject: PropTypes.func,
    onStateUpdateItem: PropTypes.func,
  };

export default EstatusV2;