import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
//import { onAuthStateChanged } from 'firebase/auth';
//import { firebaseAuth } from '../firebase/config';
import {checking, login, logout} from '../store/slice/authSlice';
import {useLocation, useNavigate} from "react-router-dom";
import Swal from "sweetalert2";

export const useAlert = () => {

    const showAlert = (title = 'Notificaciones', body='Excelente', icon='success') =>{
        Swal.fire({
            title: title,
            text: body,
            icon: icon,
            showCancelButton: false,
            confirmButtonText: 'Aceptar',
            allowOutsideClick: false
        });
    }

    return {
        showAlert
    }

}