import React, {  useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Switch from "@mui/material/Switch";

import Swal from 'sweetalert2';

function cambiarEstado(item, value,/* callback */ callback){
    callback(item, value);
}

function Estatus({item,estado, objeto, refresh}) {

    let [statex, setState] = useState( estado );

    const realizar = (value)=>{
        console.log(value);
        objeto.cambiarEstado(item, value).then(response=>{
            Swal.fire({
                title: 'Notificaciones',
                text: 'Se ha cambiado el estatus del registro.',
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false
            });
            setState( value ) ;
            refresh();
        })
    }
    
    const handleCheckChange = ({target})=>{
       realizar(target.checked);
    }

    return(
        <SoftBox mt={0.25}>
            <Switch  checked={ (statex) } onChange={ (event) => handleCheckChange(event) } />
        </SoftBox>
    )
    
}

Estatus.propTypes = {
    item: PropTypes.object,
    estado : PropTypes.bool,
    objeto: PropTypes.func,
    refresh: PropTypes.func
};

export default Estatus;